<template>
  <div>
    <label class='form-label'>Table Headers</label>
    <div class='relative mb-5'>
      <input type='text' class='form-input' v-model='additionalHeader.name' />
      <select v-model='additionalHeader.column_type' class='form-select ml-2'>
        <option v-for='columnType in columnTypes' :key='`columnType-${columnType}`'>
          {{columnType}}
        </option>
      </select>
      <button class='border ml-2 py-2 px-2 rounded bg-gray-200' @click='addToHeaders'>
        <plus-circle-icon class='inline-block' />
      </button>
      <custom-field-template-selectable-values class='mt-2 ml-8'
                                               v-if='isSelectableTableTemplate'></custom-field-template-selectable-values>
      <custom-field-template-metadata v-if='isMetadataTableTemplate' @update-metadata-role='updateAdditionalHeaderRole' />
    </div>
    <label class='form-label'>Example</label>
    <draggable v-model='tableHeaders' tag='tr'>
      <div v-for='tableHeader in tableHeaders' :key='`tableHeader-${tableHeader.name}`' class='inline-block'>
        <button @click='removeFromTableHeaders(tableHeader.name)' class='remove-selectable-button' style='right: 0.5rem; top: 0.75rem;'><x-circle-icon class='inline-bock text-red-900 opacity-75' /></button>
        <div class='selectable-value max-w-xs pl-3 pr-2 py-3 '>{{tableHeader.name}}</div>
        <div>{{ tableExamples[tableHeader.column_type] }}</div>
      </div>
    </draggable>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import CustomFieldTemplateSelectableValues from '@/views/custom_fields/CustomFieldTemplateSelectableValues.vue'
import CustomFieldTemplateMetadata         from '@/views/custom_fields/CustomFieldTemplateMetadata.vue'
import { PlusCircleIcon, XCircleIcon }  from '@vue-hero-icons/outline'
import draggable from 'vuedraggable'

export default {
  name: 'CustomFieldTemplateTableHeaders',
  components: {
    CustomFieldTemplateSelectableValues,
    CustomFieldTemplateMetadata,
    draggable,
    PlusCircleIcon,
    XCircleIcon,
  },
  data () {
    return {
      addingNewValue: false,
      additionalHeader: { name: '', column_type: '', role: '' },
      additionalValues: { name: '', values: [] },
      columnTypes: [
        'text', 'number', 'date', 'contact', 'url', 'fund', 'dropdown'
      ],
      tableExamples: {
        'text': 'Text',
        'number': 10,
        'date': '1988-06-28',
        'contact': 'Zuckerberg',
        'url': 'https://facebook.com',
        'fund': '펀드',
        'dropdown': ['data1', 'data2'],
      }
    }
  },
  computed: {
    tableHeaders: {
      get () {
        return this.editingCustomField.table_headers
      },
      set (headers) {
        this.updateCustomFieldTableHeaders(headers)
      }
    },
    ...mapState('customFields', [
      'editingCustomField',
    ]),
    ...mapGetters('customFields', [
      'isEditingExistingCustomFieldTemplate',
      'isSelectableTemplate',
    ]),
    isSelectableTableTemplate () {
      return (['dropdown']).includes(this.additionalHeader.column_type)
    },
    isMetadataTableTemplate () {
      return (['user']).includes(this.additionalHeader.column_type)
    },
  },
  methods: {
    addToHeaders () {
      if (this.additionalHeader.name.length > 0 && this.additionalHeader.column_type.length > 0) {
        this.additionalValues.name = this.additionalHeader.name
        this.additionalValues.values = this.editingCustomField.selectable_values

        var tableData = {
          header: this.additionalHeader,
          values: this.additionalValues,
        }
        this.resetCustomFieldSelectableValues()
        this.addToTableHeaders(tableData)
        this.additionalHeader = { name: '', column_type: '', role: '' }
        this.additionalValues = { name: '', values: [] }
      } else {
        // TODO: add some validation
      }
    },
    ...mapMutations('customFields', [
      'addToTableHeaders',
      'removeFromTableHeaders',
      'updateCustomFieldTableHeaders',
      'resetCustomFieldSelectableValues',
    ]),
    updateAdditionalHeaderRole (role) {
      this.additionalHeader.role = role
    }
  },
}
</script>

<style lang='scss' scoped>

.remove-selectable-button:hover  ~ .selectable-value {
  background-color: #FDF2F8;
  text-decoration: line-through;
}

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}


</style>
